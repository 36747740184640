import React from 'react'
import PropTypes from 'prop-types'
import { COMMUNICATION_PREFERENCES } from 'types'

const propTypes = {
  primaryContact: PropTypes.object,
  updateContact: PropTypes.func,
  fetchContacts: PropTypes.func,
}

function Paperless({ primaryContact, updateContact, fetchContacts }) {
  const paperlessEnabled = isAccountPaperless(primaryContact)

  return (
    <div className="card">
      <header>
        <img
          src="/assets/images/icons/with-background/ST_YIcon_Paperless.svg"
          alt=""
          className="icon"
        />
        <h2>Go Paperless</h2>
        <div className="actions">
          <div className="switch-wrapper">
            <p>{paperlessEnabled ? 'On' : 'Off'}</p>
            <label className="switch" htmlFor="paperlessSwitch">
              <input
                id="paperlessSwitch"
                aria-label="Enable paperless option"
                type="checkbox"
                checked={paperlessEnabled}
                onChange={async (event) => {
                  const updatedParams = setPaperless(
                    primaryContact,
                    event.target.checked,
                  )
                  await updateContact(updatedParams)
                  await fetchContacts(primaryContact.accountid)
                }}
              />
              <span
                className={
                  paperlessEnabled ? 'slider round checked' : 'slider round'
                }
              ></span>
            </label>
          </div>
        </div>
      </header>
      <div className="card-inner">
        <h3>What is paperless?</h3>
        <p>
          When you go paperless, we will only send you a printed membership
          card. All membership renewal notifications will be sent to your
          primary email address ({primaryContact.email}) only. No renewal
          notification will be mailed.
        </p>
      </div>
    </div>
  )
}

function setPaperless(primaryContact, paperless) {
  let preferences =
    typeof primaryContact.communication_preferences__c !== 'string'
      ? []
      : primaryContact.communication_preferences__c.split(';')
  if (paperless) {
    preferences.push(COMMUNICATION_PREFERENCES.PAPERLESS_RENEWAL)
  } else {
    preferences = preferences.filter(
      (preference) =>
        preference !== COMMUNICATION_PREFERENCES.PAPERLESS_RENEWAL,
    )
  }

  return {
    sfid: primaryContact.sfid,
    id: primaryContact.id,
    communication_preferences__c: preferences.join(';'),
  }
}

function isAccountPaperless(primaryContact) {
  const preferences = primaryContact.communication_preferences__c
  if (typeof preferences !== 'string') return false

  return preferences.includes(COMMUNICATION_PREFERENCES.PAPERLESS_RENEWAL)
}

Paperless.propTypes = propTypes

export default Paperless
