import React from 'react'
import PropTypes from 'prop-types'
import {
  AccountType,
  AppliedDiscountCodeType,
  API_KEY_MAP,
  BillingType,
  DONATION_CONTRIBUTION_AMOUNTS,
  HEROKU_ID_KEY,
  MembershipType,
  ReferralType,
  ProductPrices,
} from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import * as memberActions from 'member-actions'
import { isNil, isEmpty } from 'lodash'
import { DiscountCode, PromoCodeWarningModal } from 'member-portal/components'
import { PaymentForm } from 'member-portal/forms'
import { COUNTRY, PATH, makeMemberApplicationApplyPath } from 'config'
import {
  apiValuesWithMappedKeys,
  formValuesWithMappedKeys,
  handleSubmitFailWithFlashMessage,
  useToggle,
  calculateDiscountedCost,
  parseNumberFromString,
  apiValuesWithPaymentDetails,
  calculateReferralCreditsUsed,
} from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  autoCompleteAddress: PropTypes.func.isRequired,
  basePath: PropTypes.string.isRequired,
  billing: BillingType,
  discountCodeDetails: AppliedDiscountCodeType,
  fetchPlaceDetails: PropTypes.func.isRequired,
  gclid: PropTypes.string,
  isTrialMembershipsActive: PropTypes.bool,
  isUpgrade: PropTypes.bool,
  membership: MembershipType.isRequired,
  membershipPrices: ProductPrices.isRequired,
  nextStep: PropTypes.string.isRequired,
  normalizedReferralNumber: PropTypes.string,
  oppGuid: PropTypes.string,
  prepareOpportunityAndOLIs: PropTypes.func.isRequired,
  proceedToUpgrade: PropTypes.func.isRequired,
  promoCodeIsTrial: PropTypes.bool.isRequired,
  referralInfo: ReferralType,
  selectedMembershipType: PropTypes.string.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
  setBillingOptions: PropTypes.func.isRequired,
  setReferredByMemberNumber: PropTypes.func.isRequired,
  showAutoRenew: PropTypes.bool,
  showDiscountForm: PropTypes.bool,
  showDonationForm: PropTypes.bool,
  showMembershipSummaryCost: PropTypes.bool,
  showReferral: PropTypes.bool,
  showTrailerCareSummaryCost: PropTypes.bool,
  testVariant: PropTypes.string,
  trailerCarePrices: ProductPrices.isRequired,
  updateMembershipApplicationAccount: PropTypes.func.isRequired,
}

function PaymentStep({
  account,
  autoCompleteAddress,
  basePath,
  billing,
  discountCodeDetails,
  fetchPlaceDetails,
  gclid,
  isTrialMembershipsActive,
  membership,
  membershipPrices,
  nextStep,
  normalizedReferralNumber,
  oppGuid,
  prepareOpportunityAndOLIs,
  promoCodeIsTrial,
  referralInfo,
  selectedMembershipType,
  selectedTrailerCareType,
  setBillingOptions,
  setReferredByMemberNumber,
  showAutoRenew = false,
  showDiscountForm = false,
  showDonationForm = false,
  showMembershipSummaryCost = false,
  showReferral = false,
  showTrailerCareSummaryCost = false,
  testVariant,
  trailerCarePrices,
  updateMembershipApplicationAccount,
  isUpgrade = false,
  proceedToUpgrade,
}) {
  const history = useHistory()
  const [showPromoCodeWarningModal, togglePromoCodeWarningModal] = useToggle()

  // If user skips the boats page without entering in boat information, the
  // application should redirect to the Boat Page
  if (isEmpty(membership?.boats)) {
    return <Redirect to={makeMemberApplicationApplyPath(PATH.BOATS)} />
  }

  const { referral_credits__c: referralCredits } = account
  const originalMembershipCost = membershipPrices[selectedMembershipType]
  const trailerCareCost = trailerCarePrices[selectedTrailerCareType]
  const cartSubtotal = calculateDiscountedCost(
    originalMembershipCost,
    discountCodeDetails,
  )
  const totalPayment = (cartSubtotal ?? 0) + (trailerCareCost ?? 0)
  const discountedMembershipCost = calculateDiscountedCost(
    originalMembershipCost,
    discountCodeDetails,
  )
  const referralCreditsUsed = calculateReferralCreditsUsed(
    discountedMembershipCost,
    referralCredits,
  )

  return (
    <>
      <div>
        {showDiscountForm && (
          <DiscountCode
            formName="PaymentDiscount"
            {...{
              basePath,
              discountCodeDetails,
            }}
            {...(isTrialMembershipsActive && { promoCodeIsTrial })}
          />
        )}
        {promoCodeIsTrial && isTrialMembershipsActive && (
          <div
            className="trial-legal-language"
            dangerouslySetInnerHTML={{
              __html: discountCodeDetails?.promo_legal,
            }}
          />
        )}
        <PaymentForm
          {...{
            membership,
            autoCompleteAddress,
            fetchPlaceDetails,
            showAutoRenew,
            showDonationForm,
            showReferral,
            totalPayment,
            discountCodeDetails,
          }}
          initialValues={
            billing ??
            setInitialPaymentFormValues(
              account,
              normalizedReferralNumber,
              referralInfo,
              showAutoRenew,
            )
          }
          onSubmit={(formValues) => {
            if (
              discountCodeDetails?.autoRenewRequired &&
              !formValues.autoRenewalAgreement
            ) {
              togglePromoCodeWarningModal()
              throw new SubmissionError('Discount Code requires auto renewal')
            }

            // Set 'final donation' amount from either donationAmounts or
            // otherDollarDonationAmount and set 'subtotal' of membership price with any discounts
            // plus any trailer care package selected into billing state
            const { otherDollarDonationAmount, donationAmounts } = formValues
            const finalDonation = showDonationForm
              ? (otherDollarDonationAmount ??
                parseNumberFromString(donationAmounts))
              : 0

            const subtotalWithoutDonations =
              ((showMembershipSummaryCost && discountedMembershipCost) ?? 0) +
              ((showTrailerCareSummaryCost && trailerCareCost) ?? 0)
            const billingOptions = {
              ...formValues,
              finalDonation,
              subtotalWithoutDonations,
            }

            setBillingOptions(billingOptions)

            const { referredByMemberNumber } = formValues || {}
            setReferredByMemberNumber(referredByMemberNumber)

            const accountApiValues = apiValuesWithMappedKeys(
              formValues.account,
              API_KEY_MAP.BILLING_ADDRESS_KEYMAP,
            )

            updateMembershipApplicationAccount({
              account: {
                [HEROKU_ID_KEY]: account[HEROKU_ID_KEY],
                ...accountApiValues,
              },
              membership: {
                [HEROKU_ID_KEY]: membership[HEROKU_ID_KEY],
              },
            })

            if (isUpgrade) return proceedToUpgrade()

            return prepareOpportunityAndOLIs(
              apiValuesWithPaymentDetails({
                account,
                billing: billingOptions,
                discountCodeDetails,
                discountedMembershipCost,
                gclid,
                membership,
                referralCreditsUsed,
                referralInfo,
                referredByMemberNumber,
                oppGuid,
                testVariant,
              }),
            )
          }}
          onSubmitSuccess={() => {
            history.push(nextStep)
          }}
          onSubmitFail={handleSubmitFailWithFlashMessage}
        />
      </div>
      {showPromoCodeWarningModal && (
        <PromoCodeWarningModal handleClose={togglePromoCodeWarningModal} />
      )}
    </>
  )
}

PaymentStep.propTypes = propTypes

function mapStateToProps(state) {
  return {
    billing: memberSelectors.billing(state),
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    gclid: memberSelectors.gclid(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
    membershipPrices: memberSelectors.membershipPrices(state),
    normalizedReferralNumber: memberSelectors.normalizedReferralNumber(state),
    oppGuid: memberSelectors.oppGuid(state),
    promoCodeIsTrial: memberSelectors.promoCodeIsTrial(state),
    referralInfo: memberSelectors.referralInfo(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    testVariant: memberSelectors.testVariant(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
  }
}

const mapDispatchToProps = {
  autoCompleteAddress: apiActions.autoCompleteAddress,
  fetchPlaceDetails: apiActions.fetchPlaceDetails,
  setBillingOptions: memberActions.setBillingOptions,
  setReferredByMemberNumber: memberActions.setReferredByMemberNumber,
  prepareOpportunityAndOLIs: apiActions.prepareOpportunityAndOLIs,
  updateMembershipApplicationAccount:
    apiActions.updateMembershipApplicationAccount,
  proceedToUpgrade: apiActions.proceedToUpgrade,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PaymentStep,
)

const setInitialPaymentFormValues = (
  account,
  normalizedReferralNumber,
  referralInfo,
  showAutoRenew,
) => {
  const initialValues = {}
  initialValues.account = formValuesWithMappedKeys(
    account,
    API_KEY_MAP.BILLING_ADDRESS_KEYMAP,
  )
  const {
    billingstreet,
    billingpostalcode,
    shippingstreet,
    shippingpostalcode,
  } = account

  const billingAddressIsSet = !isNil(billingstreet)

  initialValues.billingAddressSameAsShipping =
    !billingAddressIsSet ||
    (billingstreet === shippingstreet &&
      billingpostalcode === shippingpostalcode)

  if (!billingAddressIsSet) {
    initialValues.account.country = COUNTRY.UNITED_STATES
  }

  if (showAutoRenew) initialValues.autoRenewalAgreement = true
  initialValues.donationAmounts = DONATION_CONTRIBUTION_AMOUNTS.NO_DONATION

  initialValues.referredByMemberNumber = normalizedReferralNumber
  initialValues.fetchedReferrerMemberNumber =
    referralInfo?.fetchedReferrerMemberNumber
  initialValues.fetchedReferrerMemberName =
    referralInfo?.fetchedReferrerMemberName

  return initialValues
}
