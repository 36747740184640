import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { PageHeader } from 'components'
import { Contacts, PasswordAndUsername } from 'global-account/components'
import { AccountHolder, Paperless } from '../components'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { AccountType, ContactType } from 'types'
import { PATH, QUERY_KEY, makeMemberPortalPath } from 'config'
import { parseQuery, sortContacts, withApiAuth } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  contacts: PropTypes.arrayOf(ContactType).isRequired,
  deleteContact: PropTypes.func.isRequired,
  fetchAccount: PropTypes.func.isRequired,
  fetchContacts: PropTypes.func.isRequired,
  primaryContact: ContactType,
  requestPasswordChange: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
}

function Account({
  account,
  contacts,
  deleteContact,
  fetchAccount,
  fetchContacts,
  primaryContact,
  requestPasswordChange,
  updateAccount,
  updateContact,
}) {
  const sortedContacts = sortContacts(contacts, primaryContact)
  const { account_detail_type__c, sfid: accountId, shippingcountry } = account
  const { search } = useLocation()
  const query = parseQuery(search)
  const formOpen = !isEmpty(query[QUERY_KEY.FORM_OPEN])

  return (
    <>
      <PageHeader
        backLinkText="Back to Membership Dashboard"
        backLinkTo={makeMemberPortalPath(PATH.MEMBERSHIPS)}
        headerText="Account Details & Preferences"
      />

      <section className="form-block-container">
        <AccountHolder
          account={account}
          formOpen={formOpen}
          primaryContact={primaryContact}
          updateAccount={updateAccount}
          fetchAccount={fetchAccount}
        />
        <PasswordAndUsername requestPasswordChange={requestPasswordChange} />
        <Paperless
          primaryContact={primaryContact}
          updateContact={updateContact}
          fetchContacts={fetchContacts}
        />
        <Contacts
          accountId={accountId}
          accountType={account_detail_type__c}
          backLink={makeMemberPortalPath(PATH.ACCOUNT)}
          contacts={sortedContacts}
          country={shippingcountry}
          updateContact={updateContact}
          fetchContacts={fetchContacts}
          deleteContact={deleteContact}
        />
      </section>
    </>
  )
}

Account.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: globalSelectors.account(state),
    contacts: globalSelectors.contacts(state),
    primaryContact: globalSelectors.primaryContact(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  deleteContact: apiActions.deleteContact,
  fetchAccount: apiActions.fetchAccount,
  fetchContacts: apiActions.fetchContacts,
  requestPasswordChange: apiActions.requestPasswordChange,
  updateAccount: apiActions.updateAccount,
  updateContact: apiActions.updateContact,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps),
)(Account)
