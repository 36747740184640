import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useHistory } from 'react-router-dom'
import { first, noop } from 'lodash'
import { Button, Modal } from 'lp-components'
import MembershipSelectionButton from './MembershipSelectionButton'
import SingleMembershipRedirect from './SingleMembershipRedirect'
import { MembershipArrayType } from 'types'
import { PATH, makeMemberPortalPath, makeMembershipIdPath } from 'config'
import { boatMake, findPrimaryBoat } from 'utils'

const propTypes = {
  memberships: MembershipArrayType.isRequired,
  memberApplicationFlow: PropTypes.string.isRequired,
  nextStep: PropTypes.string.isRequired,
  isUpgrade: PropTypes.bool,
}

function MembershipSelection({
  isUpgrade = false,
  memberships,
  memberApplicationFlow,
  nextStep,
}) {
  const totalApplicableMemberships = memberships.length

  if (totalApplicableMemberships === 0) {
    return <NoApplicableMembershipsError workflow={memberApplicationFlow} />
  }

  // Don't present a choice of memberships if only 1 membership is
  // applicable...
  if (totalApplicableMemberships === 1) {
    return (
      <SingleMembershipRedirect
        membership={first(memberships)}
        nextStep={nextStep}
      />
    )
  }

  return (
    <section className="form-block-container questionnaire-flow">
      <div className="questionnaire-flow-inner">
        <header className="header-title">
          <h1>Renew</h1>
        </header>
        <div className="card card-medium">
          <div className="card-inner">
            <h2>{`Please Select the Membership for ${memberApplicationFlow}:`}</h2>
            <div className="text-button-group">
              {memberships.map((membership) => {
                const {
                  membership_expiration_date__c: expDate,
                  membership_number__c: membershipNumber,
                  membership_type__c: membershipType,
                  sfid: membershipId,
                } = membership
                const primaryBoat = findPrimaryBoat(membership.boats)
                return (
                  <MembershipSelectionButton
                    key={membershipId}
                    isUpgrade={isUpgrade}
                    membershipType={membershipType}
                    nextStepPath={makeMembershipIdPath(membershipId, nextStep)}
                  >
                    <>
                      <p>Membership #: {membershipNumber}</p>
                      <p>Expiration Date: {expDate}</p>
                      <p>Primary Boat: {boatMake(primaryBoat)}</p>
                    </>
                  </MembershipSelectionButton>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

MembershipSelection.propTypes = exact(propTypes)

export default pure(MembershipSelection)

function NoApplicableMembershipsError({ workflow }) {
  const history = useHistory()

  return (
    <Modal preventClose={true} onClose={noop}>
      <p>No applicable memberships available for {workflow}</p>
      <Button
        onClick={() => {
          history.replace(makeMemberPortalPath(PATH.MEMBERSHIPS))
        }}
      >
        Back to Member Center
      </Button>
    </Modal>
  )
}
