import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  Field,
  SubmissionError,
  getFormValues,
  propTypes as formPropTypes,
} from 'redux-form'
import { useHistory, useLocation } from 'react-router-dom'
import { isNil } from 'lodash'
import { lpForm } from 'lp-form'
import { HomePortAddress, HomePortMap } from '../components'
import { Checkbox, Input, SubmitButton, Button, Select } from 'lp-components'
import {
  BoatType,
  ENGINE_NUMBER,
  ENGINE_TYPES,
  FUEL_TYPES,
  HULL_TYPES,
} from 'types'
import classnames from 'classnames'
import { SEATOW_PHONE, SEATOW_PHONE_DIGITS } from 'config'
import {
  areValuesDefined,
  boatMake,
  getHomePortFromFormValues,
  homePortTitleByType,
  navigateToNextStepWithState,
  useToggle,
} from 'utils'

const propTypes = {
  boat: BoatType,
  existingMemberBoat: PropTypes.bool,
  nextStep: PropTypes.string.isRequired,
  primaryBoat: BoatType,
  ...formPropTypes,
}

// This form is reused for both editing an existing boat, in which case the
// "boat" property has a value. Otherwise, a new boat is being created and
// "boat" does not have a value.

function BoatForm({
  boat,
  existingMemberBoat = false,
  formValues,
  handleSubmit,
  nextStep,
  primaryBoat,
  pristine,
  submitting,
}) {
  const [isPrimaryBoat, changeIsPrimaryBoat] = useToggle()
  const location = useLocation()
  const history = useHistory()
  const newBoat = isNil(boat)
  const primary = !newBoat ? boat.primary_boat__c : false

  const homePort = getHomePortFromFormValues(formValues)
  const {
    address,
    city,
    country,
    homePortName,
    homePortType,
    state,
    lat,
    lng,
  } = homePort
  const hasHomePort = areValuesDefined(lat, lng)
  const newMemberBoat = !existingMemberBoat

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="group-block">
        <div className="row">
          <Field
            className="col-3"
            component={Input}
            label="Year"
            name="year"
            required
            requiredIndicator="*"
            validate={validYear}
            normalize={normalizeYear}
          />
          <Field
            className="col-3"
            component={Input}
            label="Length (in ft)"
            name="length"
            required
            requiredIndicator="*"
            type="number"
            validate={boatLengthSupported}
          />
          <Field
            className="col-6"
            component={Input}
            label="Boat Make"
            name="boatMake"
            required
            requiredIndicator="*"
          />
        </div>
        {!existingMemberBoat && (
          <p>
            (If you own more than one boat your primary boat should be the one
            you use most often. You can add additional boat information after
            joining by logging into your account.)
          </p>
        )}
        {existingMemberBoat && (
          <>
            <Field
              component={Input}
              label="Registration/Documentation Number"
              name="registrationNumber"
            />
            <div className="row">
              <Field
                className="col-6"
                component={Input}
                label="Boat Name"
                name="boatName"
              />
              <Field
                className="col-6"
                component={Input}
                label="Boat Color"
                name="boatColor"
              />
            </div>
            <div className="row">
              <Field
                className="col-3"
                component={Select}
                label="Hull Type"
                name="hullType"
                options={HULL_TYPES}
                placeholder="Select"
              />
              <Field
                className="col-3"
                component={Select}
                label="Fuel Type"
                name="fuelType"
                options={FUEL_TYPES}
                placeholder="Select"
              />
              <Field
                className="col-3"
                component={Select}
                label="Engine Type"
                name="engineType"
                options={ENGINE_TYPES}
                placeholder="Select"
              />
              <Field
                className="col-3"
                component={Select}
                label="Number of Engines"
                name="numberOfEngines"
                options={ENGINE_NUMBER}
                placeholder="Select"
              />
            </div>
          </>
        )}
      </div>

      <div className="group-block small-spacing">
        <h3 className="group-title home-port">Boat Home Port</h3>
        <div className="inner-text-action">
          <p>
            Indicate the marina, home dock, or mooring where this boat is kept,
            or the trailer ramp you use the majority of the time.
          </p>
          {hasHomePort && (
            <Button
              className="button-primary-outline"
              onClick={() =>
                navigateToNextStepWithState(
                  history,
                  nextStep,
                  formValues,
                  location,
                  boat,
                )
              }
            >
              Edit Boat Home Port
            </Button>
          )}
        </div>

        {hasHomePort && (
          <>
            <HomePortMap lat={lat} lng={lng} />
            <div className="map-content">
              {newMemberBoat ? (
                <h3>{homePortTitleByType(homePortType)}</h3>
              ) : (
                <h3>Boat Home Port Address</h3>
              )}
              <Button
                className="link-primary"
                onClick={() =>
                  navigateToNextStepWithState(
                    history,
                    nextStep,
                    formValues,
                    location,
                    boat,
                  )
                }
              >
                (Edit Boat Home Port)
              </Button>
              <HomePortAddress
                address={address}
                city={city}
                country={country}
                name={homePortName}
                state={state}
              />
            </div>
          </>
        )}

        {((newBoat && isNil(location?.state?.formData)) || !hasHomePort) && (
          <Button
            className="button-primary-outline plus-left-button-icon"
            onClick={() =>
              navigateToNextStepWithState(
                history,
                nextStep,
                formValues,
                location,
                boat,
              )
            }
          >
            Add Home Port
          </Button>
        )}
      </div>

      {existingMemberBoat && !primary && (
        <div className="group-block medium-spacing">
          <h3 className="group-title">Assign as your Primary Boat</h3>
          {newBoat ? (
            <>
              <p>Your current primary boat is {boatMake(primaryBoat)}</p>
              <div className="switch-wrapper">
                <label className="switch" htmlFor="primaryBoatSwitch">
                  <Field
                    id="primaryBoatSwitch"
                    aria-label="Assign as primary boat"
                    component={Checkbox}
                    label=" "
                    name="primaryBoat"
                    onChange={changeIsPrimaryBoat}
                  />
                  <span
                    className={classnames('slider', 'round', {
                      checked: isPrimaryBoat,
                    })}
                  ></span>
                </label>
                <p>{isPrimaryBoat ? 'Yes' : 'No'}</p>
              </div>
            </>
          ) : (
            <p>
              Your current primary boat is {boatMake(primaryBoat)}. Please call{' '}
              <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a> (
              {SEATOW_PHONE_DIGITS}) to reassign this boat as your primary
            </p>
          )}
        </div>
      )}

      <div className="button-group">
        <SubmitButton
          submitting={submitting}
          pristine={
            !newMemberBoat && pristine && isNil(location?.state?.formData)
          }
        >
          {newMemberBoat
            ? 'Save & Continue'
            : newBoat
              ? 'Submit'
              : 'Save Boat Updates'}
        </SubmitButton>
      </div>
    </form>
  )
}

const beforeSubmit = (formValues, { boat, existingMemberBoat }) => {
  // Check to see if for new boats, the user has gone through the homeport
  // wizard. If so, the type collected through the wizard will not be undefined
  if (isNil(formValues.homePortType)) {
    throw new SubmissionError({
      _error: 'Add a home port to your boat!',
    })
  }

  // For new boats of existing members and it is not a brand new member account
  // ("boat" is undefined), the primaryBoat Boolean must be set,
  // even if the user does not change its value in the form...
  if (existingMemberBoat) {
    if (isNil(boat)) {
      const { primaryBoat } = formValues

      formValues = { ...formValues, primaryBoat: primaryBoat ?? false }
    }
  }

  return formValues
}

BoatForm.propTypes = propTypes

function mapStateToProps(state, { form }) {
  return {
    formValues: getFormValues(form)(state),
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'BoatForm',
    constraints: {
      year: {
        presence: { allowEmpty: false },
        length: {
          is: 4,
          message: 'must be 4 digits',
        },
      },
      length: {
        presence: { allowEmpty: false },
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 10,
          lessThan: 100,
        },
      },
      boatMake: { presence: { allowEmpty: false } },
    },
    enableReinitialize: true,
  }),
  connect(mapStateToProps),
)(BoatForm)

function boatLengthSupported(length) {
  if (!length) return
  if (length > 65)
    return `Service not available in all areas for vessels over 65'. Please call ${SEATOW_PHONE} for details.`
}

function validYear(year) {
  if (!year) return

  // A boat's year cannot be newer than one greater than the current year
  const currentYear = new Date().getFullYear()

  if (year > currentYear + 1) {
    return 'Boat year must be no greater than current year + 1'
  }
}

function normalizeYear(year) {
  if (!year) return
  return year.replace(/[^\d]/g, '')
}
