import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button, Modal } from 'lp-components'
import { noop } from 'lodash'

const propTypes = {
  errorMessage: PropTypes.string.isRequired,
}

function TrailerCareErrorModal({ errorMessage }) {
  const history = useHistory()

  return (
    <Modal preventClose={true} onClose={noop}>
      <p>{errorMessage}</p>
      <div>
        <Button
          onClick={() => {
            history.goBack()
          }}
        >
          Back
        </Button>
      </div>
    </Modal>
  )
}

TrailerCareErrorModal.propTypes = propTypes

export default TrailerCareErrorModal
